import React, { useState, useEffect, useMemo } from "react";
import { TFProgressBar, TFCard, DialogDemo, LineChart } from "../../Shared";
import { InputText } from "primereact/inputtext";
import { useSelector, useDispatch } from "react-redux";
import { Calendar } from "primereact/calendar";

const getTrendDataOptoins = (tankLevelData, tankPressure, volume, dateTime) => {
  const trendDataObject = {
    labels: dateTime,
    datasets: [
      {
        label: "Tank Level",
        data: tankLevelData,
        fill: false,
        yAxisID: 'y',
        tension: .4,
        borderColor: "#42A5F5",
      },
      {
        label: "Tank Pressure",
        data: tankPressure,
        fill: false,
        yAxisID: 'y1',
        tension: .4,
        borderColor: "#FFA726",
      },
      {
        label: "Volume",
        data: volume,
        fill: false,
        yAxisID: 'y3',
        tension: .4,
        borderColor: "#66BB6A",
      },

    ],
  };
  return trendDataObject;
};

const WedgitOne = (props) => {

  const [location, setLocationName] = useState(props.location);
  const [phoneNo, setPhoneNo] = useState(props.phoneNo);
  const [volumeCapacity, setVolumeCapcity] = useState(props.volumeCapacity);
  const [tankLevel, setTankLevel] = useState(props.tankLevel);
  const [tankPressure, setTankPressure] = useState(props.tankPressure);
  const [levelAlert, setlevelAlert] = useState(props.levelAlert);
  const [volume, setVolume] = useState(props.volume);

  const [showTankLevel, setShowTankLevel] = useState(props.showTankLevel);
  const [showTankPressure, setShowTankPressure] = useState(props.showTankPressure);
  const [showVolume, setShowVolume] = useState(props.showVolume);

  const hubMessage = useSelector((state) => state.signalRHubReducer);
  const [fromDateTrend, setFromDateTrend] = useState();
  const [toDataTrend, setToDateTrend] = useState();
  const [fromDateReport, setFromDateReport] = useState();
  const [toDataReport, setToDateReport] = useState();
  const [trendData, setTrendData] = useState(getTrendDataOptoins([], [], [], []));

  const [value, setValue] = useState();
  useEffect(() => {
    if (hubMessage && hubMessage.phoneNo === phoneNo) {
      setTankLevel(hubMessage.tankLevel);
      setTankPressure(hubMessage.tankPressure);
      setVolume(hubMessage.volume);
    }
  }, [phoneNo, tankLevel, tankPressure, hubMessage]);

  useEffect(() => {
    setTankLevel(props.tankLevel);
    setTankPressure(props.tankPressure);
    setVolume(props.volume);
    setlevelAlert(props.levelAlert);
    setVolumeCapcity(props.volumeCapacity);
    setLocationName(props.location);
    setShowTankLevel(props.showTankLevel)
    setShowTankPressure(props.showTankPressure)
    setShowVolume(props.showVolume)

  }, [props.tankLevel,
  props.tankPressure,
  props.volume,
  props.levelAlert,
  props.volumeCapacity,
  props.location,
  props.showTankLevel,
  props.showTankPressure,
  props.showVolume

  ]);

  const generateReportAndTrend = (type) => {
    if (type === "Report") {
      GetHeartBeatReport(fromDateReport, toDataReport, phoneNo);
    }
    if (type === "Trend") {
      GetHeartBeatTrend(fromDateTrend, toDataTrend, phoneNo);
    }
  };
  const cleanTrend = (type) => {

    if (type === "Trend") {
      setTrendData(
        getTrendDataOptoins([], [], [], []));
      setFromDateTrend();
      setToDateTrend();
    }
  }

  const getDate = (input) => {
    let date = input ? new Date(input) : new Date();

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    let formatedDate =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + strTime;
    return formatedDate;
  };

  const GetHeartBeatReport = (from, to, phoneNo) => {
    let url = window._teleUrl + `/api/HearBeats/Export/?phoneNo=${phoneNo}&from=${getDate(
      from
    )}&to=${getDate(to)}`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "text/csv",
      },
    })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((uril) => {
        var link = document.createElement("a");
        link.href = uril;
        link.download = "Report" + new Date() + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const GetHeartBeatTrend = (from, to, phoneNo) => {
    let url = window._teleUrl + `/api/HearBeats/ByNo/?phoneNo=${phoneNo}&from=${getDate(
      from
    )}&to=${getDate(to)}`;
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((messages) => {
        let tankLevel = [];
        let tankPressure = [];
        let dateTimeAll = [];
        let volume = [];
        if (messages.length > 0) {
          messages.map((x) => {
            tankLevel.push(x.tankLevel);
            tankPressure.push(x.tankPressure);
            volume.push(x.volume);
            dateTimeAll.push(x.dateTime);
          });
          setTrendData(
            getTrendDataOptoins(tankLevel, tankPressure, volume, dateTimeAll)
          );
        }
      });
  };

  let tankLevelUI;
  let tankPressureUi;
  let linePressuer;
  let volumeUI;

  if (showTankLevel) {
    tankLevelUI = <div className="TF-r-f">
      <label
        htmlFor={"txtTankLevel" + props.phoneNo}
        className="TFLabel"
      >
        Tank Level:
      </label>
      <InputText
        id={"txtTankLevel" + props.phoneNo}
        readOnly="readOnly"
        className="TFtextBox"
        value={tankLevel}
      // onChange={(e) => setTankLevel(e.target.value)}
      />
      <span>%</span>
    </div>;
  }

  if (showTankPressure) {
    tankPressureUi = <div className="TF-r-f">
      <label
        htmlFor={"txtTankPresure" + props.phoneNo}
        className="TFLabel"
      >
        Tank Pressure:
      </label>
      <InputText
        id={"txtTankPresure" + props.phoneNo}
        readOnly="readOnly"
        className="TFtextBox"
        value={tankPressure}
        onChange={(e) => setTankPressure(e.target.value)}
      />
      <span>BAR</span>
    </div>;
  }
  if (showVolume) {
    volumeUI = <div className="TF-r-f">
      <label htmlFor={"txtVolume" + props.phoneNo} className="TFLabel">
        Volume:
      </label>
      <InputText
        id={"txtVolume" + props.phoneNo}
        className="TFtextBox"
        readOnly="readOnly"
        value={volume}
      // onChange={(e) => setValue(e.target.value)}
      />
      <span>Liter</span>
    </div>;
  }


  linePressuer = <div className="TF-r-f hide">
    <label
      htmlFor={"txtLinePresure" + props.phoneNo}
      className="TFLabel"
    >
      Line Pressure:
    </label>
    <InputText
      id={"txtLinePresure" + props.phoneNo}
      className="TFtextBox"
      value={value}
    // onChange={(e) => setValue(e.target.value)}
    />
    <span>BAR</span>

  </div>;




  return (
    <TFCard key={phoneNo + "phoneNo"} title="">
      <div className="wedgitText">
        <span>Location : </span>
        <span>{location}</span>
      </div>
      <div className="wedgitText">
        <span>Volume Capacity : </span>
        {/* <span><InputText id={"volText" + props.phoneNo} className="TFtextBox clean-TextBox" value={volumeCapcity} onChange={(e) => setVolumeCapcity(e.target.value)} /></span> */}
        <span>{volumeCapacity}</span>
        <span>Liter O2</span>
      </div>
      <div className="p-grid">
        <div className="p-col-4">
          <TFProgressBar
            id="pro1"
            progress={tankLevel}
            levelAlert={levelAlert}
          />
        </div>
        <div className="p-col-8">
          <div className="formGroup">
            {tankLevelUI}
            {volumeUI}
            {tankPressureUi}
            {linePressuer}
            <div className="TF-r-f">
              <label
                htmlFor={"txtLevelAlert" + props.phoneNo}
                className="TFLabel"
              >
                Level Alert:
              </label>
              <InputText
                id={"txtLevelAlert" + props.phoneNo}
                className="TFtextBox"
                readOnly="readOnly"
                value={levelAlert}
              // onChange={(e) => setlevelAlert(e.target.value)}
              />
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <DialogDemo
        width={"980px"}
        generateReportAndTrend={generateReportAndTrend}
        cleanTrend={cleanTrend}
        type="Report"
        header="Report"
        ButtonName="Report"
        ButtonText="Report"
        BtnIcon="pi pi-book"
      >
        <div className="p-grid">
          <div className="p-col-6">
            <label className="clFrom" htmlFor="icon">
              From
            </label>
            <Calendar
              showTime hourFormat="12"
              inline
              id="to-report-icon"
              value={fromDateReport}
              onChange={(e) => setFromDateReport(e.value)}
            />
          </div>
          <div className="p-col-6">
            <label className="clFrom" htmlFor="icon">
              To
            </label>
            <Calendar
              showTime hourFormat="12"
              inline
              id="from-report-icon"
              value={toDataReport}
              onChange={(e) => setToDateReport(e.value)}
            />
          </div>
        </div>
      </DialogDemo>
      <DialogDemo
        width={"1214px"}
        generateReportAndTrend={generateReportAndTrend}
        cleanTrend={cleanTrend}
        type="Trend"
        header="Trend"
        ButtonName="Trend"
        ButtonText="Trend"
        BtnIcon="pi pi-chart-line"
      >
        <div className="p-grid">
          <div className="p-col-6">
            <label className="trandLabel" htmlFor="icon">
              From
            </label>
            <Calendar
              showTime hourFormat="12"
              inline
              id="to-trend-icon"
              value={fromDateTrend}
              onChange={(e) => setFromDateTrend(e.value)}
              showIcon
            />
          </div>
          <div className="p-col-6">
            <label className="trandLabel" htmlFor="icon">
              To
            </label>
            <Calendar
              showTime hourFormat="12"
              inline
              id="from-trend-icon"
              value={toDataTrend}
              onChange={(e) => setToDateTrend(e.value)}
              showIcon
            />
          </div>
        </div>
        <LineChart data={trendData} type="line" chartTile="Line Chart" />
      </DialogDemo>
    </TFCard>
  );
};

export default WedgitOne;
