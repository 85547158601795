export const setHeartBeatMessage = (value) =>{
    return {
        type:"setData",
        payload:value
    }
}
export const isTimerAllow = (value) =>{
    return {
        type:"isTimerAllow",
        payload:value
    }
}
