import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from "react-redux";
import { isTimerAllow } from '../../Actions/index'
import './DialogDemo.css';

const DialogDemo = ({ children, generateReportAndTrend, cleanTrend, type, width, header, ButtonName, BtnIcon, ButtonText }) => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');
    const dispatch = useDispatch();
    const dialogFuncMap = {
        'displayBasic': setDisplayBasic,
    }

    const onClick = (name, position) => {
        dispatch(isTimerAllow(false));
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name, type) => {

        dispatch(isTimerAllow(true));
        dialogFuncMap[`${name}`](false);
        cleanTrend(type);
    }
    const generate = (type) => {
        generateReportAndTrend(type);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => onHide(name, type)} className="p-button-text" />
                <Button label="Generate" icon="pi pi-check" onClick={() => generate(type)} autoFocus />
            </div>
        );
    }

    return (
        <div className="dialog-demo aling-right-modal-btn">
            <div className="card">
                <Button label={ButtonText} icon={BtnIcon} className={ButtonName} onClick={() => onClick('displayBasic')} />
                <Dialog header={header} visible={displayBasic} style={{ width: width }} footer={renderFooter('displayBasic')} onHide={() => onHide('displayBasic', type)} baseZIndex={1000}>
                    {children}
                </Dialog>

            </div>
        </div>
    )
}

export default DialogDemo;