import { AppHeader } from '../Shared'
import {
  BrowserRouter as Router,
  NavLink
} from "react-router-dom";

let AllPages = ['Page1', "Page2", "Page3"];
const Header = (props) => {
  let navLinks = props.AllPages.map((x) => {
    return <li key={x.recId}><NavLink activeClassName="active" to={'/' + x.name}>{x.name}</NavLink></li>
  })
  return (
    <AppHeader className="AppBar" id="AppBar"
      setCurrentClient={props.setCurrentClient} AllClients={props.AllClients} AllPages={props.AllPages} currentClient={props.currentClient}
      userInfo={props.userInfo}
      refreshCount={props.refreshCount}
      setRefreshCount={props.setRefreshCount}
    >
      <ul>
        {navLinks}
      </ul>

    </AppHeader>

  )
}

export default Header;