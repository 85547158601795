import signalRHubReducer from './signalRHubReducer';
import TimerAllowReducer from './TimerAllowReducer'
// import loggedReducer from './isLoggeds';
// import MultiplCounter from './multiplCounter';

import { combineReducers } from 'redux';

const rootAllReducers = combineReducers({
    TimerAllowReducer,
    signalRHubReducer,
   
});
export default rootAllReducers;