import { useState } from 'react';

export default function useUserInformation() {
    const getUserInfo = () => {
        const userInfoString = localStorage.getItem('userInfo');
        const userInfo = JSON.parse(userInfoString);
        return userInfo;
    };

    const [userInfo, setUserInformation] = useState(getUserInfo());

    const saveUser = userInfo => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        setUserInformation(userInfo);
    };

    return {
        setUser: saveUser,
        userInfo
    }
}


