import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('tokenTelemetry');
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const [tokenTelemetry, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('tokenTelemetry', JSON.stringify(userToken));
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        tokenTelemetry
    }
}