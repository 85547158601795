// export {default as GetHeartBeatReport} from './GetHeartBeatReport'


const UserAuth = (credentials) => {
    return fetch(window._teleUrl + '/Api/Login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            return json;
        });
}
export default UserAuth;


