import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Password as Psw } from 'primereact/password';
import UserAuth from '../Api/userAuth'
import '../Layout/login.css';





const Login = ({ setToken, setUser }) => {

    const [Username, setUserName] = useState();
    const [Password, setPassword] = useState();

    const loginUser = async (credentials) => {
        return UserAuth(credentials);
    }
    const handleSubmit = async e => {
        e.preventDefault();
        const msg = await loginUser({
            Username,
            Password
        });

        if (msg && msg.check) {
            setUser(msg.data);
            setToken(msg.telemetryToken);
        }
        else {
            if (msg && msg.message) alert(msg.message);
            setToken(false);

        }

    }

    return (
        <div className="form-demo">
            <div className="p-d-flex p-jc-center">
                <div className="card login">
                    <h3 className="p-text-center">Login</h3>
                    <form onSubmit={handleSubmit} className="p-fluid">
                        <div className="p-field">
                            <span className="p-float-label">
                                <label htmlFor="UserName" className="p-sr-only">User Name</label>
                                <InputText id="UserName" onChange={e => setUserName(e.target.value)} name="UserName" placeholder="UserName*" />

                            </span>
                        </div>
                        <div className="p-field">
                            <span className="p-float-label">
                                <label htmlFor="Password" className="p-sr-only">Password</label>
                                <Psw id="Password" onChange={e => setPassword(e.target.value)} name="Password" placeholder="Password*" toggleMask feedback={false} />
                            </span>
                        </div>
                        <Button type="submit" label="Login" className="p-mt-2" />
                    </form>
                </div>
            </div>
        </div>

    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
export default Login;