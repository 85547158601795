import { React, useEffect, useState } from 'react'
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import './Header.css'

// const citySelectItems = [
//     { label: 'New York', value: 'NY' },
//     { label: 'Rome', value: 'RM' },
//     { label: 'London', value: 'LDN' },
//     { label: 'Istanbul', value: 'IST' },
//     { label: 'Paris', value: 'PRS' }
// ];

const AppHeader = ({ className, id, setCurrentClient, AllClients, currentClient, userInfo,
    refreshCount,
    setRefreshCount,
    children, model }) => {
    const [iconeValueDanger, seticonvalue] = useState("p-component p-button-rounded p-button-success p-button-icon-only")

    const onClientChange = (e) => {
        setCurrentClient(e.value);
    }
    const handleLogout = e => {
        localStorage.removeItem("tokenTelemetry");
        localStorage.removeItem("userInfo");
        window.location.reload();
    }
    const handleRefresh = e => {
        setRefreshCount(refreshCount + 1);
    }

    return (
        <>
            <div className="card">

                <div id={id} className={className + " NavbarTF"}>
                    <div className="refresh-btn" data-name="refresh" data-key="59704">
                        <Button onClick={handleRefresh}
                            icon={"pi pi-refresh"} className={"p-component p-button-rounded  p-button-icon-only"}
                        />
                    </div>
                    {children}
                    <div className="dropdown-demo">
                        <div className="card">
                            <Dropdown className={"dpClient"} value={currentClient} options={AllClients} onChange={onClientChange} optionLabel="name" placeholder="Select a Client" />
                        </div>
                    </div>
                    {/* <Button
                        icon={"pi pi-volume-up"} className={"audioButton " + iconeValueDanger}
                        onClick={(e) => seticonvalue("p-component p-button-rounded p-button-danger p-button-icon-only")}
                    /> */}


                    <Button onClick={(e) => handleLogout()} className={"p-button p-component vimeo p-p-0"}>
                        <span className="p-px-3">LogOut</span>
                    </Button>


                </div>

            </div>
        </>
    )
}

export default AppHeader;