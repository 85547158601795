import { React, useState, useEffect } from "react";
import { WedgitOne } from "./Components";

const chunk = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (value, index) =>
    array.slice(index * size, index * size + size)
  );

const itemsPerChunk = 3;

const Pages = (props) => {
  const [firstChunk, setFirstChunk] = useState([]);
  const [secondChunk, setSecondChunk] = useState([]);

  let WedgitOneUIChuk1;
  let WedgitOneUIChunk2;

  useEffect(() => {
    fetch(window._teleUrl + "/api/Tanks/" + props.pageRecId)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        const newArray = chunk(json, itemsPerChunk);
        setFirstChunk(newArray[0]);
        if (newArray.length > 1) {
          setSecondChunk(newArray[1]);
        }
      });
  }, [props.pageRecId, props.refreshCount]);


  if (firstChunk && firstChunk.length > 0) {
    WedgitOneUIChuk1 = firstChunk.map((data, idx) => {
      const widgControl = (
        <div className="p-col-4" key={data.phoneNo + "div"}>
          <WedgitOne
            key={data.phoneNo + "wi"}
            location={data.location}
            volumeCapacity={data.volumeCapacity}
            phoneNo={data.phoneNo}
            tankLevel={data.lastHeartBeat.tankLevel}
            tankPressure={data.lastHeartBeat.tankPressure}
            volume={data.lastHeartBeat.volume}
            showTankLevel={data.showTankLevel}
            showTankPressure={data.showTankPressure}
            showVolume={data.showVolume}
            levelAlert={data.levelAlert}
            unitMeasurement={data.unitMeasurement}
          />
        </div>
      );
      return widgControl;
    });
  }
  if (secondChunk && secondChunk.length > 0) {
    WedgitOneUIChunk2 = secondChunk.map((data, idx) => {
      const widgControl = (
        <div className="p-col-4" key={data.phoneNo + "div"}>
          <WedgitOne
            key={data.phoneNo + "wi"}
            location={data.location}
            volumeCapacity={data.volumeCapacity}
            phoneNo={data.phoneNo}
            tankLevel={data.lastHeartBeat.tankLevel}
            tankPressure={data.lastHeartBeat.tankPressure}
            volume={data.lastHeartBeat.volume}
            showTankLevel={data.showTankLevel}
            showTankPressure={data.showTankPressure}
            showVolume={data.showVolume}
            levelAlert={data.levelAlert}
            unitMeasurement={data.unitMeasurement}

          />
        </div>
      );
      return widgControl;
    });
  }
  return (
    <div className="container">
      <div className="p-grid">{WedgitOneUIChuk1}</div>

      <div className="p-grid">{WedgitOneUIChunk2}</div>
    </div>
  );
};
export default Pages;
