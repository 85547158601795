import { Card } from 'primereact/card';

const TFCard = ({title, children}) => {
    return (
        <>
        <Card title={title}>
            { children }
        </Card>
        </>
    )
}

export default TFCard;