import React from "react";
import ReactDOM from "react-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./index.css";
import './responsive.css'
import App from "./App";

import { createStore } from "redux";
import rootAllReducers from "./Reducers/index";
import { Provider } from "react-redux";
window._teleUrl = "https://admin.alamtelemetry.com";
//window._teleUrl = "http://localhost:5300";
const store = createStore(
  rootAllReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
window.siteBaseUrl =
  window.location.protocol + "//" + window.location.host + "/";
window.runTimer = true;
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
