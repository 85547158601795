import React from 'react'
import { Spring } from 'react-spring/renderprops'
import './Progress.css'

const TFProgressBar = ({ id, className, progress,levelAlert }) => {
  let alertClass = progress <= levelAlert ? "red-alert" : "";

  return (
    <Spring from={{ percent: 0 }} to={{ percent: progress }}>
      {({ percent }) => (
        <div className="progress vertical">
          <div style={{ height: `${percent}%` }} className={"progress-bar " + alertClass}>
            <span className="sr-only">{`${progress}%`}</span>
          </div>
        </div>
      )}
    </Spring>
  )
}

export default TFProgressBar;