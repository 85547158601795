import Header from "./Layout/Header";
import Pages from "./Layout/Pages";
import Login from "./Layout/Login";
// import Page2 from "./Layout/Page2";
import React, { useState, useEffect } from "react";
import * as signalR from "@aspnet/signalr";
import { useSelector, useDispatch } from "react-redux";
import { setHeartBeatMessage } from "./Actions/index";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import useToken from './Auth/useToken';
import useEffectAsync from './CustomHook/useEffectAsync'

import useUserInformation from './Api/useUserInformation'
import "./App.css";



function App() {
  const [loader, setLoader] = useState([]);
  const { tokenTelemetry, setToken } = useToken();
  const { userInfo, setUser } = useUserInformation();


  useEffect(() => {
    setLoader(true);
  }, [userInfo]);


  if (!tokenTelemetry) {
    return <Login setToken={setToken} setUser={setUser} />
  }

  let mainApp = null;

  if (loader) {
    mainApp = <MainApp userInfo={userInfo} />
  }

  return (
    <div className="App">
      {mainApp}
    </div>
  );
}


function MainApp(props) {

  const dispatch = useDispatch();
  const [AllPages, setAllPages] = useState([]);
  const [AllClients, setAllClients] = useState([]);
  const [currentClient, setCurrentClient] = useState();
  const [refreshCount, setRefreshCount] = useState(0);
  const [hubConnection, setConnection] = useState(null);



  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(window._teleUrl + "/messageHub")
      .build();
    newConnection.serverTimeoutInMilliseconds = 1000 * 1000;
    setConnection(newConnection);
  }, []);



  useEffect(() => {
    if (hubConnection) {
      hubConnection
        .start()
        .then((result) => {
          hubConnection.on("sendToReact", (message) => {
            console.log(message);
            dispatch(setHeartBeatMessage(message));
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [hubConnection, dispatch]);




  useEffectAsync(async () => {

    if (props.userInfo) {
      let url = window._teleUrl + "/api/Clients/" + props.userInfo.username + "/" + props.userInfo.isAdmin + "";
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json && json.check) {
            setAllClients(json.data)
          }
          return json;
        });
    }


  }, []);


  useEffectAsync(async () => {

    if (currentClient && currentClient.recId) {
      let url = window._teleUrl + "/api/Stations/" + currentClient.recId;
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json) {
            setAllPages(json);
          }
          return json;
        });
    }

  }, [refreshCount]);

  useEffectAsync(async () => {

    if (currentClient && currentClient.recId) {
      let url = window._teleUrl + "/api/Stations/" + currentClient.recId;
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          if (json) {
            setAllPages(json);
          }
          return json;
        });
    }

  }, [currentClient]);


  let navRoute = null;
  navRoute = AllPages.map((x) => {
    return (
      <Switch>
      <Route key={"route" + x.recid} exact path={"/" + x.name}>
        <Pages
          pageName={x.name}
          pageRecId={x.recId}
          refreshCount={refreshCount}
        />
      </Route>
      </Switch>
    );
  });

  return (
    <div className="App">
      <Router>
        <Header
          userInfo={props.userInfo}
          setCurrentClient={setCurrentClient}
          AllClients={AllClients}
          AllPages={AllPages}
          currentClient={currentClient}
          refreshCount={refreshCount}
          setRefreshCount={setRefreshCount}
        />
        
          {navRoute}
        
      </Router>
    </div>
  );

}



export default App;
